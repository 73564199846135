import React, { useState, useEffect } from "react";
import { Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { BiSearch } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { getUpcomingStreamList } from "../api/upcomingStream";
import s from "./liveStreams.module.css";

const UpcomingStreams = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [streamList, setStreamList] = useState([]);
  const [pageLength, setPageLength] = useState(1);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUpcomingStreams();
  }, [pageCount, search]);

  async function fetchUpcomingStreams() {
    setIsLoading(true);
    try {
      const params = {
        page: pageCount,
        limit: 8,
        type: "upcoming",
        astro_id: search.trim(),
      };
      const { data } = await getUpcomingStreamList(params);
      if (data?.status) {
        setStreamList(data?.data || []);
        setPageLength(data?.total_pages || 1);
      } else {
        console.log("Error fetching data");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      borderLeft: "1px solid #00000011",
      "&:last-child": {
        borderRight: "1px solid #00000011",
      },
    },
  }));

  return (
    <div>
      <div className={s["beat_heading"]}>
        <div className={s["user-list-heading"]}>
          <div className="beat_left" style={{ display: "flex", gap: "10px" }}>
            <div
              className={s["title"]}
              onClick={() => navigate("/add-live-stream", { state: { pagetype: "Add" } })}
            >
              <IoMdAdd /> Add Live Stream
            </div>
          </div>
          <div className={s["search-box"]} style={{ marginLeft: "auto", paddingRight: "10rem" }}>
            <span style={{ paddingRight: "1rem" }}>
              <BiSearch size={23} />
            </span>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setPageCount(1);
                setSearch(e.target.value);
              }}
              placeholder="Search by astro ID"
            />
          </div>
        </div>
        <div className="table-container">
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Session ID</StyledTableCell>
                <StyledTableCell align="center">Astro ID</StyledTableCell>
                <StyledTableCell align="center">Display Name</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Start Time</StyledTableCell>
                <StyledTableCell align="center">End Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {streamList.length > 0 ? (
                streamList?.map((stream) => (
                  <TableRow key={stream?.id}>
                    <StyledTableCell align="center">{stream?.id}</StyledTableCell>
                    <StyledTableCell align="center">{stream?.astro_id}</StyledTableCell>
                    <StyledTableCell align="center">{stream?.display_name}</StyledTableCell>
                    <StyledTableCell align="center">
  {`${new Date(new Date(stream?.created_at).getTime() + 5.5 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10)} ${new Date(new Date(stream?.created_at).getTime() + 5.5 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[1]
    .slice(0, 5)}`}
</StyledTableCell>

                    <StyledTableCell align="center">
                    {`${new Date(new Date(stream?.livestream_start_time).getTime() + 5.5 * 60 * 60 * 1000)
  .toISOString()
  .slice(0, 10)} ${new Date(new Date(stream?.livestream_start_time).getTime() + 5.5 * 60 * 60 * 1000)
  .toISOString()
  .split("T")[1]
  .slice(0, 5)}`}

                    </StyledTableCell>
                    <StyledTableCell align="center">
                    {`${new Date(new Date(stream?.livestream_end_time).getTime() + 5.5 * 60 * 60 * 1000)
  .toISOString()
  .slice(0, 10)} ${new Date(new Date(stream?.livestream_end_time).getTime() + 5.5 * 60 * 60 * 1000)
  .toISOString()
  .split("T")[1]
  .slice(0, 5)}`}

                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <DataNotFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {streamList.length > 0 && (
            <div className={s["pagination"]}>
              <Pagination
                count={pageLength}
                size="large"
                onChange={(e, value) => setPageCount(value)}
                page={pageCount}
              />
            </div>
          )}
        </div>
        <Loder loading={isLoading} />
      </div>
    </div>
  );
};

export default UpcomingStreams;