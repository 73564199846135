import { Autocomplete, Card, Chip, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { addPackage, updatePackage } from "../api/pujaPackage";
import s from "./Puja.module.css";

const AddPujaPackage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState([]);
  const [specification, setSpecification] = useState("");
  const [ members, setMembers ] = useState("")
  const [mrp, setMrp] = useState("");
  const [price, setPrice] = useState("");
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("draft");
  const [id, setId] = useState("")

  const pagetype = location.state.pagetype;

  useEffect(() => {
    setId(location?.state?.data?.id)
    setName(location?.state?.data?.name);
    setSpecification(location?.state?.data?.specification);
    setDescription(location?.state?.data?.description || []);
    setSelectedDescription(location?.state?.data?.description || []);
    setMembers(location?.state?.data?.no_of_members)
    setMrp(location?.state?.data?.mrp);
    setPrice(location?.state?.data?.price);
    setStatus(location?.state?.data?.status)
  }, [location]);

  const packageFunc = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("description", JSON.stringify(selectedDescription));
      fd.append("specification", specification);
      fd.append("no_of_members", members);
      fd.append("mrp", mrp);
      fd.append("price", price);
      fd.append("status", status);
      {file && fd.append("package_image", file)}
      try {
        let res = await addPackage(fd);
        if (res.data.status) {
          navigate("/puja-package");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("description", JSON.stringify(selectedDescription));
      fd.append("specification", specification);
      fd.append("no_of_members", members);
      fd.append("mrp", mrp);
      fd.append("price", price);
      fd.append("status", status);
      {file && fd.append("package_image", file)}
      try {
        let res = await updatePackage(fd, id);
        if (res.data.status) {
          navigate("/puja-package");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  console.log("status", status)

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["back-button"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

             {/* Name */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Name</label>
                <textarea type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
              </div>
            </Grid>

             {/* Image */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setFile(e.target.files[0])} />
                </div>
              </div>
            </Grid>

             {/* Description */}
             <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Description</label>
                <div className="mr-2">
                  <div className="form-group">
                  <Autocomplete
                   multiple
                   className={s["chips"]}
                   id="process-autocomplete"
                   options={description?.filter((item) => !selectedDescription.includes(item))}
                   freeSolo
                   value={selectedDescription}
                   getOptionLabel={(option) => option}  
                   onChange={(event, newValue) => {
                    setSelectedDescription(newValue.map((item) => (typeof item === 'string' ? item : item)));
                  }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={option}
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </div>
                </div>
              </div>
            </Grid>

             {/* Specification */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Specification</label>
                <textarea type="text" className="form-control" name="specification" value={specification} onChange={(e) => setSpecification(e.target.value)} placeholder="Specification" />
              </div>
            </Grid>

             {/* Members */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Members</label>
                <textarea type="text" className="form-control" name="members" value={members} onChange={(e) => setMembers(e.target.value)} placeholder="Members" />
              </div>
            </Grid>

             {/* MRP */}
              <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">MRP</label>
                <input type="text" className="form-control" name="mrp" value={mrp} onChange={(e) => setMrp(e.target.value)} placeholder="MRP" />
              </div>
            </Grid>

             {/* Price */}
              <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Price</label>
                <input type="text" className="form-control" name="price" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" />
              </div>
            </Grid>

            {/* Status */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Status</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                      <option value={"draft"}>Draft</option>
                      <option value={"published"}>Published</option>
                      {/* <option value={"deleted"}>Deleted</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => packageFunc()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddPujaPackage;
