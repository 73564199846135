import React from "react";
import AddPujaPackage from "../components/Puja/AddPujaPackage";

const PujaPackageAdd = () => {
  return (
      <AddPujaPackage />
  );
};

export default PujaPackageAdd;
