import React, { useState } from "react";
import { Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Common/Custombutton";
import { BiArrowBack } from "react-icons/bi";
import { notificationHandler } from "../../utils/Notification";
import { addLiveStream } from "../api/upcomingStream";
import s from "./liveStreams.module.css";

const AddLiveStream = () => {
  const navigate = useNavigate();
  const [astroId, setAstroId] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleSubmit = async () => {

    
    const requestData = {
      astro_id: astroId,
      start_time: new Date(startTime).toISOString(),
      end_time: new Date(endTime).toISOString(),
    };

    try {
      const res = await addLiveStream(requestData);
      if (res.data.status) {
        notificationHandler({ type: "success", msg: res.data.message });
        navigate("/scheduled-streams");
      } else {
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.error(error);
    }
  };


  return (
    <div>
      <Card className={s["admin_container"]}>
      <div className={s["back-button"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <div className="form-group">
              <label>Astro ID</label>
              <input
                type="number"
                className="form-control"
                value={astroId}
                onChange={(e) => setAstroId(e.target.value)}
                placeholder="Enter Astro ID"
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className="form-group">
              <label>Start Time</label>
              <input
                type="datetime-local"
                className="form-control"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                placeholder="Enter Start Time"
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className="form-group">
              <label>End Time</label>
              <input
                type="datetime-local"
                className="form-control"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                placeholder="Enter End Time"
              />
            </div>
          </Grid>
        </Grid>

        <div className={s["form-login-btn"]} onClick={handleSubmit}>
          <CustomButton>Submit</CustomButton>
        </div>
      </Card>
    </div>
  );
};

export default AddLiveStream;