import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { updatePujaOrders } from "../api/pujaOrders";
import s from "./Puja.module.css";

const UpdateOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState("");
  const [id, setId] = useState("")
  const [videoLink, setVideoLink] = useState(""); 

  const pagetype = location.state.pagetype;


  useEffect(() => {
    setId(location?.state?.data?.order?.id)
    setStatus(location?.state?.data?.order?.status)
    setVideoLink(location?.state?.data?.order?.video_link || "")
  }, [location]);

  const categoryFunc = async () => {
    let temp = {
      ...location?.state?.data,
      status: status,
      video_link: videoLink,
    }

    if (pagetype == "Edit") {
      try {
        let res = await updatePujaOrders(temp, id);
        if (res.data.status) {
          navigate("/puja-orders");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["back-button"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            
            {/* Status */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Status</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={"initiated"}>Initiated</option>
                      <option value={"paid"}>Paid</option>
                      <option value={"scheduled"}>Scheduled</option>
                      <option value={"performed"}>Performed</option>
                      <option value={"delivered"}>Delivered</option>
                      <option value={"cancelled"}>Cancelled</option>
                      <option value={"refunded"}>Refunded</option>
                    </select>
                  </div>
                </div>
              </div>
            </Grid>

            {/* Video Link */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="videoLink">Video Link</label>
                <input
                  type="text"
                  className="form-control"
                  value={videoLink}
                  onChange={(e) => setVideoLink(e.target.value)}
                  placeholder="Enter video link"
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => categoryFunc()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default UpdateOrders;
