import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Pagination,
    Chip,
  } from "@mui/material";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell, { tableCellClasses } from "@mui/material/TableCell";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import { styled } from "@mui/material/styles";
  import React, { useEffect, useState } from "react";
  import { BiSearch } from "react-icons/bi";
  import Loder from "../../Loder/Loder";
  import DataNotFound from "../ErrorPage/DataNotFound";
  import { getLiveStreamList } from "../api/liveStream";
  import s from "./liveStreams.module.css";
  
  const filterMapper = {
    "All": "",
    "Completed": "completed",
    "Ongoing": "ongoing",
  };
  
  const CurrentPastStreams = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [streamList, setStreamList] = useState([]);
    const [pageLength, setPageLength] = useState(1);
    const [search, setSearch] = useState("");
    const [pageCount, setPageCount] = useState(1);
    const [statusFilter, setStatusFilter] = useState("All");
  
    useEffect(() => {
      fetchLiveStreamList();
    }, [pageCount, search, statusFilter]);
  
    async function fetchLiveStreamList() {
      setIsLoading(true);
      try {
        const params = {
          page: pageCount,
          limit: 8,
          astro_id: search.trim(),
          status: filterMapper[statusFilter],
        };
        let { data } = await getLiveStreamList(params);
        if (data?.status) {
          setStreamList(data?.data);
          setPageLength(data?.total_pages);
        } else {
          console.log("Error fetching data");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "var(--clr-theme)",
        color: theme.palette.common.white,
        fontWeight: "bold",
        textAlign: "center",
        borderRight: "1px solid #ddd",
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        textAlign: "center",
        borderLeft: "1px solid #00000011",
        "&:last-child": {
          borderRight: "1px solid #00000011",
        },
      },
    }));
  
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#f2f7fb",
      },
    }));
  
    const getDisplayStatus = (status) => {
      return status === "ended" ? "Completed" : "Ongoing";
    };
  
    return (
      <div className="beat_table">
        <div className={s["heading"]}>
          <div style={{ display: "flex", gap: "1rem" }}>
            {Object.keys(filterMapper).map((key) => (
              <Chip
                key={key}
                label={key}
                onClick={() => {
                  setStatusFilter(key);
                  setPageCount(1);
                }}
                color={statusFilter === key ? "primary" : "default"}
              />
            ))}
          </div>
  
          <div className={s["search-box"]} style={{ marginLeft: 'auto', paddingRight: "10rem" }}>
            <span style={{ paddingRight: "1rem" }}>
              <BiSearch size={23} />
            </span>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setPageCount(1);
                setSearch(e.target.value);
              }}
              placeholder="Search by astro ID..."
            />
          </div>
        </div>
  
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Session ID</StyledTableCell>
              <StyledTableCell>Astro ID</StyledTableCell>
              <StyledTableCell style={{ width: "250px" }}>Display Name</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
              <StyledTableCell>Duration</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Revenue</StyledTableCell>
              <StyledTableCell>Unique Views</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {streamList.length > 0 ? (
              streamList?.map((stream) => (
                <StyledTableRow key={stream?.id}>
                  <StyledTableCell>{stream?.id}</StyledTableCell>
                  <StyledTableCell>{stream?.astro_id}</StyledTableCell>
                  <StyledTableCell>{stream?.display_name}</StyledTableCell>
                  <StyledTableCell>
                  {`${new Date(new Date(stream?.created_at).getTime() + 5.5 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10)} ${new Date(new Date(stream?.created_at).getTime() + 5.5 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[1]
    .slice(0, 5)}`}
  
                  </StyledTableCell>
                  <StyledTableCell>{stream?.duration}</StyledTableCell>
                  <StyledTableCell>{getDisplayStatus(stream?.status)}</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <DataNotFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {streamList.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination
              count={pageLength}
              size="large"
              onChange={(e, value) => setPageCount(value)}
              page={pageCount}
            />
          </div>
        )}
        <Loder loading={isLoading} />
      </div>
    );
  };
  
  export default CurrentPastStreams;