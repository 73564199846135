import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const liveStreamBaseUrl = getBaseUrl() + "admin/liveStreams";

export const getUpcomingStreamList = async (data) => {
  let url = `${liveStreamBaseUrl}?page=${data.page}&limit=${data.limit}`;
  
  if (data.astro_id) {
    url += `&astro_id=${data.astro_id}`;
  }

  if (data.status) {
    url += `&status=${data.status}`;
  }

  if (data.type) {
    url += `&type=${data.type}`;
  }

  let config = {
    method: "get",
    url: url,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addLiveStream = async (data) => {
  const config = {
    method: "post",
    url: liveStreamBaseUrl,
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    return res;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};