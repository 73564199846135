import React from "react";
import LiveStreams from "../components/Live/LiveStreams";

const LiveStream = () => {
  return (
    <>
      <LiveStreams/>
    </>
  );
};

export default LiveStream;