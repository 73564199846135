import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const mandir = getBaseUrl() + "admin/temples";
const updateMandir = getBaseUrl() + "admin/temples";
const deleteMandir = getBaseUrl() + "admin/temples";
const addMandir = getBaseUrl() + "admin/temples";

export const getMandirList = async (data = {}) => {
  let url = mandir;

  if (Object.keys(data).length) {
    let queryParams = [];

    if (data.page) queryParams.push(`page=${data.page}`);
    if (data.limit) queryParams.push(`limit=${data.limit}`);
    if (data.search) queryParams.push(`search=${data.search}`);

    if (queryParams.length) {
      url += `?${queryParams.join("&")}`;
    }
  }

  let config = {
    method: "get",
    url: url,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

  export const updateMandirList = async (data, id) => {
    let config = {
      method: "patch",
      url: updateMandir + `/${id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const deleteMandirList = async (data) => {
    let config = {
      method: "delete",
      url: deleteMandir + `/${data.id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const addMandirList = async (data) => {
    let config = {
      method: "post",
      url: addMandir,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };