import React from "react";
import ChatDetail from "../components/ChatDetails/ChatDetail";

const ChatDetails = () => {
  return (
    <div>
      <ChatDetail />
    </div>
  );
};

export default ChatDetails;