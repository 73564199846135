import { Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { getPujaOrders } from "../api/pujaOrders";
import s from "./Puja.module.css";

const Orders = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, serOrders] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setPageLength] = useState();
  const [search, setsearch] = useState("");

  useEffect(() => {
    fetchOrders();
  }, [pageCount, search]);

  async function fetchOrders() {
    setIsLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getPujaOrders(temp);
      if (res.data.status) {
        serOrders(res?.data?.data);
        setPageLength(res?.data?.data?.totalPages);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  return (
    <div className="">
      <h3>Puja Orders</h3>
      <div className={s["beat_heading"]}>
        <div className={s["user-list-heading"]}>
          <div style={{
          display:"flex",
          gap:"10px"
        }} className="beat_left">
          </div>
          <div className={s["user-list-search"]}>
          <div className={s["search-box"]}>
            <span style={{ paddingRight: "0.5rem" }}>
              <BiSearch size={23} />
            </span>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setpageCount(1);
                setsearch(e.target.value);
              }}
              placeholder="Search by title..."
            />
          </div>
        
        </div>
        </div>
     
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Created At</StyledTableCell>
              <StyledTableCell align="center">Order Id</StyledTableCell>
              <StyledTableCell align="center">User Name</StyledTableCell>
              <StyledTableCell align="center">User Phone no.</StyledTableCell>
              <StyledTableCell align="center">Order Status</StyledTableCell>
              <StyledTableCell align="center">Puja Title</StyledTableCell>
              <StyledTableCell align="center">Scheduled Date</StyledTableCell>
              <StyledTableCell align="center">Price</StyledTableCell>
              <StyledTableCell align="center">Payment Status</StyledTableCell>
              <StyledTableCell align="center">Package</StyledTableCell>
              <StyledTableCell align="center">Prasad Status</StyledTableCell>
              <StyledTableCell align="center">Delivery Address</StyledTableCell>
              <StyledTableCell align="center">Devotee Names</StyledTableCell>
              <StyledTableCell align="center">Devotee Gothra</StyledTableCell>
              <StyledTableCell align="center">Devotee Referred By</StyledTableCell>
              <StyledTableCell align="center">Devotee WhatsApp No.</StyledTableCell>
              <StyledTableCell align="center">Devotee Wish</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orders?.data?.map((row) => (
             <StyledTableRow key={row?.order?.id}>
               <StyledTableCell align="center">{`${row?.order?.created_at?.slice(
                    0,
                    10
                  )} ${row?.order?.created_at
                    ?.split("T")[1]
                    ?.slice(0, 5)}`}</StyledTableCell>
                <StyledTableCell align="center">{row?.order?.id}</StyledTableCell>
                <StyledTableCell align="center">{row?.user_name} </StyledTableCell>
                <StyledTableCell align="center">{row?.user_phone} </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                   className={
                    row?.order?.status?.toLowerCase() === "initiated" ? s["initiated"] :
                    row?.order?.status?.toLowerCase() === "paid" ? s["paid"] :
                    row?.order?.status?.toLowerCase() === "scheduled" ? s["scheduled"] :
                    row?.order?.status?.toLowerCase() === "performed" ? s["performed"] :
                    row?.order?.status?.toLowerCase() === "delivered" ? s["delivered"] :
                    row?.order?.status?.toLowerCase() === "cancelled" ? s["cancelled"] :
                    row?.order?.status?.toLowerCase() === "refunded" ? s["refunded"] : s["default"]
                  }
                  >
                    {row?.order?.status?.toUpperCase()}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">{row?.product?.name} </StyledTableCell>
                <StyledTableCell align="center">{`${row?.product?.actual_pooja_time?.slice(
                    0,
                    10
                  )} ${row?.product?.actual_pooja_time
                    ?.split("T")[1]
                    ?.slice(0, 5)}`}</StyledTableCell>
                  <StyledTableCell align="center">{row?.order?.discounted_amount} </StyledTableCell>
               
                <StyledTableCell align="center">
                  <div
                    className={
                      row?.order?.payment_status?.toLowerCase() === "pending" ? s["draft"] :
                      row?.order?.payment_status?.toLowerCase() === "success" ? s["paid"] :
                      row?.order?.payment_status?.toLowerCase() === "falied" ? s["deleted"] : ""
                    }
                  >
                    {row?.order?.payment_status?.toUpperCase()}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">{row?.packageDetails?.name} </StyledTableCell>
                <StyledTableCell align="center">{row?.sankalpFormDetails?.is_prasad} </StyledTableCell>
                <StyledTableCell align="center">{row?.sankalpFormDetails?.delivery_address} </StyledTableCell>
                <StyledTableCell align="center"> {row?.sankalpFormDetails?.devotee_names?.join(", ")} </StyledTableCell>
                <StyledTableCell align="center">{row?.sankalpFormDetails?.gothra} </StyledTableCell>
                <StyledTableCell align="center">{row?.sankalpFormDetails?.referred_by} </StyledTableCell>
                <StyledTableCell align="center">{row?.sankalpFormDetails?.whatsapp_number} </StyledTableCell>
                <StyledTableCell align="center">{row?.sankalpFormDetails?.wish} </StyledTableCell>

                <StyledTableCell align="center">
                  <div className="astrologer-action-btn">
                    <span
                      onClick={() => {
                        navigate("/edit-puja-orders", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }}
                    >
                      Edit
                    </span>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {orders?.data?.length <= 0 && <DataNotFound />}
        {orders?.data?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => {setpageCount(value)}} page={pageCount} />
          </div>
        )}
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};

export default Orders;
