import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const liveStreamBaseUrl = getBaseUrl() + "admin/liveStreams";

export const getLiveStreamList = async (data) => {
  let config = {
    method: "get",
    url: `${liveStreamBaseUrl}?page=${data.page}&limit=${data.limit}&astro_id=${data.astro_id}&status=${data.status}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};