import React, { useState } from "react";
import { Card, Grid } from "@mui/material";
import Custombutton from "../../Common/Custombutton";
import { getChatDetails } from "../api/getChatDetails";
import s from "./ChatDetail.module.css";

const FetchChatDetails = () => {
    const [chatId, setChatId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const fetchChatDetailsFunc = async () => {
        if (!chatId.trim()) {
            setErrorMessage("Chat ID cannot be empty.");
            return;
        }
        setErrorMessage("");
        try {
            const response = await getChatDetails(chatId);
            if (response.status === 200) {
                setSuccessMessage("Chat details fetched successfully.");
            } else {
                setErrorMessage(response.data?.message || "Enter valid chat ID.");
            }
        } catch (error) {
            setErrorMessage("An error occurred while fetching chat details.");
        }
    };

    return (
        <div>
            <div className={s["user-list-heading"]}>
                <h3>Fetch chat details by entering Chat ID</h3>
            </div>
            <Card className={s["admin_container"]} style={{ marginBottom: "1rem", padding: "1rem" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={8}>
                        <input
                            type="text"
                            id="chatIdInput"
                            className="form-control"
                            placeholder="Enter firebase channel ID here..."
                            value={chatId}
                            onChange={(e) => setChatId(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <div className="mr-2" style={{ width: "50%" }} onClick={fetchChatDetailsFunc}>
                            <Custombutton>SUBMIT</Custombutton>
                        </div>
                    </Grid>
                </Grid>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
            </Card>
        </div>
    );
};

export default FetchChatDetails;