import { Box, Tab, Tabs } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CurrentPastStreams from "./CurrentPastStreams";
import UpcomingStreams from "./UpcomingStreams";

const LiveStreams = () => {
  const { route } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(route || "current-past-streams");

  useEffect(() => {
    if (route !== value) {
      setValue(route);
    }
  }, [route, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/${newValue}`);
  };

  const componentMapper = {
    "current-past-streams": CurrentPastStreams,
    "scheduled-streams": UpcomingStreams,
  };

  const Component = componentMapper[value] || (() => <div>Oops, Something went wrong!</div>);

  return (
    <div>
      <h3>Live Streams</h3>
      <Box sx={{ width: "100%", marginBlock: "1rem" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="live stream tabs"
        >
          <Tab value="current-past-streams" label="Current And Past Streams" />
          <Tab value="scheduled-streams" label="Scheduled Streams" />
        </Tabs>
      </Box>

      <Component />
    </div>
  );
};

export default LiveStreams;