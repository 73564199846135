import { Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { addBenefits, updateBenefits } from "../api/pujaBenefits";
import s from "./Puja.module.css";

const AddPujaBenefits = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");
  const [id, setId] = useState("")

  const pagetype = location.state.pagetype;

  useEffect(() => {
    setId(location?.state?.data?.id)
    setTitle(location?.state?.data?.title);
    setDescription(location?.state?.data?.description);
    setStatus(location?.state?.data?.status);
  }, [location]);

  const benefitsFunc = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("title", title);
      fd.append("description", description);
      fd.append("icon", file);
      fd.append("status", status);
      try {
        let res = await addBenefits(fd);
        if (res.data.status) {
          navigate("/puja-benefits");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("title", title);
      fd.append("description", description);
     {file && fd.append("icon", file)}
      fd.append("status", status)
      try {
        let res = await updateBenefits(fd, id);
        if (res.data.status) {
          navigate("/puja-benefits");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["back-button"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

             {/* Name */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Title</label>
                <input type="text" className="form-control" name="name" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
              </div>
            </Grid>

             {/* Description */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Description</label>
                <textarea type="text" className="form-control" name="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
              </div>
            </Grid>

            {/* Image */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Select Icon</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setFile(e.target.files[0])} />
                </div>
              </div>
            </Grid>

            {/* Status */}
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Status</label>
                <div className="mr-2">
                  <div className="form-group">
                    <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                      <option value={"draft"}>Draft</option>
                      <option value={"published"}>Published</option>
                      {/* <option value={"deleted"}>Deleted</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => benefitsFunc()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddPujaBenefits;
