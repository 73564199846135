import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const packages = getBaseUrl() + "admin/packages";

  export const getPackageList = async (data = {}) => {
    let url = packages;
  
    if (Object.keys(data).length) {
      let queryParams = [];
  
      if (data.page) queryParams.push(`page=${data.page}`);
      if (data.limit) queryParams.push(`limit=${data.limit}`);
      if (data.search) queryParams.push(`search=${data.search}`);
  
      if (queryParams.length) {
        url += `?${queryParams.join("&")}`;
      }
    }
  
    let config = {
      method: "get",
      url: url,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
    };

  export const updatePackage = async (data, id) => {
    let config = {
      method: "patch",
      url: packages + `/${id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const deletePackage = async (data) => {
    let config = {
      method: "delete",
      url: packages + `/${data.id}`,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const addPackage = async (data) => {
    let config = {
      method: "post",
      url: packages,
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };